<!--
 * 订单页
-->

<template>
  <div>
    <div class="order-box" :class="!isApp ? 'appTop' : ''">
      <van-tabs
        @click="onChangeTab"
        :color="'#8EE4C8'"
        background="#1C172A"
        line-height="3"
        line-width="16"
        :title-active-color="'#fff'"
        shrink
      >
        <van-tab title="全部" :name="0"></van-tab>
        <van-tab title="待支付" :name="1"></van-tab>
        <van-tab title="支付成功" :name="2"></van-tab>
        <van-tab title="已取消" :name="3"></van-tab>
      </van-tabs>
      <van-list v-model:loading="loading" :finished="finished" @load="onLoad" :offset="100">
        <div class="wrapper">
          <div v-for="(item, index) in list" :key="index" class="">
            <div class="order-item-box">
              <div class="order-item-header">
                <span>订单编号：{{ item.trade_no }}</span>
                <span>{{ getTime(item.created_time) }}</span>
              </div>
              <div class="order-item-content">
                <div class="item-info">
                  <div class="item-img-name">
                    <div class="itemImg">
                      <img :src="item.image_icon" alt="" srcset="" />
                      <!-- <img src="@/imgs/comm/Slice31.png" alt="" /> -->
                    </div>
                    <div class="" style="width: 100%">
                      <div class="content-box">
                        <div class="name">
                          <!--  -->
                          <p class="title">
                            {{
                              item.sell_type == 1
                                ? item.collections_name
                                : item.pay_price / 100 + '元预付款自动抢购特权'
                            }}
                          </p>
                          <!-- item.status !== 2 &&
                            item.status !== 3 && -->
                          <p v-if="item.sell_type == 1 && item.items_name" class="desc">
                            {{ item.items_name }} x {{ item.batch_count }}
                          </p>
                          <p v-else-if="item.sell_type == 1" class="desc">
                            x {{ item.batch_count }}
                          </p>
                        </div>
                        <!-- origin_price -->
                        <div class="item-price">¥{{ item.collections_price / 100 }}</div>
                      </div>

                      <div class="prepaid-box" v-if="item.sell_type == 2">
                        <div class="">
                          <div
                            class="prepaid"
                            v-if="item.items_id == 0 && item.auto_get_time == null"
                          >
                            预付款
                          </div>
                        </div>

                        <!-- <div class="">
                        <span class="has-back" v-if="item.refund_status >= 2">已退款</span>
                        <div class="" v-else>
                          <span class="pay-scucess" v-if="item.status == 1">支付成功</span>
                          <span class="has-back" v-else>
                            {{ orderStatus[item.status] }}
                          </span>
                        </div>
                      </div> -->
                      </div>
                    </div>
                  </div>

                  <!-- <div class="item-button" v-if="!item.status">
              <button type="button" @click.stop="showPopup">取消订单</button>
              <button type="button" class="pay-btn">支付订单</button>
            </div> -->
                </div>

                <div class="point" v-if="item.discount_price > 0">
                  <div class="">
                    积分抵扣<span>(消耗{{ item.discount_price }}积分)</span>
                  </div>
                  <div class="">- ¥ {{ item.discount_price / 100 }}</div>
                </div>
                <div class="item-bt">
                  <div class="">
                    <span
                      :class="item.status == 1 ? 'pay-scucess' : ''"
                      v-if="
                        (item.status === 1 || item.status === 2 || item.status === 3) &&
                        item.sell_type == 1
                      "
                    >
                      {{ orderStatus[item.status] }}
                    </span>
                    <div class="" v-if="item.sell_type == 2">
                      <span class="has-back" v-if="item.refund_status >= 2">已退款</span>
                      <div class="" v-else>
                        <span class="pay-scucess" v-if="item.status == 1">支付成功</span>
                        <span class="has-back" v-else>
                          {{ orderStatus[item.status] }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <div class="heji">
                      合计 <span>{{ item.pay_price / 100 }}</span>
                    </div>
                    <div class="item-time" v-if="!item.status">
                      <div class="item-button" v-if="!item.status">
                        <button
                          type="button"
                          class="cancel"
                          @click.stop="showPopup(item.id, index)"
                        >
                          取消订单
                        </button>
                        <button type="button" class="pay-btn" @click.stop="goToDetail(item)">
                          支付订单
                        </button>
                      </div>
                      <span class="time">
                        <van-count-down
                          class="countDown"
                          millisecond
                          format="mm:ss"
                          :time="diffTime(item.expire_time)"
                          @change="e => changeTime(e, item, index)"
                          @finish="finistTime"
                        />
                      </span>
                      <span>后订单将自动取消</span>
                    </div>

                    <div
                      class="back"
                      v-if="item.sell_type == 2 && item.refund_status == 1"
                      @click="backPoup(item, index)"
                    >
                      申请退款
                    </div>
                  </div>
                </div>

                <!-- <div
                class="status-box"
                :class="item.sell_type == 2 && item.refund_status == 1 ? 'max-box' : ''"
              >
                <p
                  :class="item.status == 1 ? 'pay-scucess' : ''"
                  v-if="
                    (item.status === 1 || item.status === 2 || item.status === 3) &&
                    item.sell_type == 1
                  "
                >
                  {{ orderStatus[item.status] }}
                </p>
                <div
                  class="back"
                  v-if="item.sell_type == 2 && item.refund_status == 1"
                  @click="backPoup(item, index)"
                >
                  申请退款
                </div>
              </div> -->
              </div>
            </div>
          </div>
        </div>
      </van-list>
      <div v-if="isLoading" class="dataNullBox">
        <div>
          <img
            src="./../imgs/loading/loading.gif"
            style="width: 2rem; height: 2rem"
            alt=""
            srcset=""
          />
        </div>
        <div class="text">数据加载中……</div>
      </div>
      <div v-else-if="isLoadError" class="dataNullBox">
        <div>
          <img src="./../imgs/comm/dataNull.png" alt="" srcset="" />
        </div>
        <div class="text" @click="getList">数据加载失败，请检查网络设置后刷新页面</div>
      </div>
      <div v-else-if="!list.length" class="dataNullBox">
        <div>
          <img src="./../imgs/comm/dataNull.png" alt="" srcset="" />
        </div>
        <div class="text">暂无数据</div>
      </div>

      <popup
        v-model:show.sync="show"
        title="取消订单"
        desc="取消订单后售卖可能已结束，确定取消订单吗？"
        :btncon="{ cancel: '关闭弹窗', success: '取消订单' }"
        @confirm="cancelOrder"
        @cancel="show = false"
      />
      <popup
        v-model:show.sync="backshow"
        title="申请退款"
        desc="预付款自动抢购特权可享受最高级别的自动抢购特权，是否继续退款？"
        :btncon="{ cancel: '取消', success: '退款' }"
        @confirm="sureBack"
        @cancel="backshow = false"
      />
      <van-popup class="vantPop" v-model:show="knownBack">
        <div class="popup">
          <div class="content">
            <div class="title">退款申请已提交</div>
            <div>
              你的退款申请已提交，{{ orderItem.pay_price / 100 }}元退款将原路返回你的「{{
                changePay(orderItem.pay_type)
              }}」，最迟24小时到账，请注意查收~
            </div>
          </div>
          <div class="btn">
            <div @click.stop="knownIt">知道了</div>
          </div>
        </div>
      </van-popup>
      <div class="" v-if="showPayTyoe">
        <PayType
          v-model:show="showPayTyoe"
          :box="boxitem"
          :payStatus="payStatus"
          @close="showPayTyoe = false"
          :isOrder="true"
          :batch_count="boxitem.batch_count"
        />
      </div>

      <OpenTheBox v-if="showOpenBox" @close="showOpenBox = false" :order="order" />
      <PayCbNew v-if="showNewBox" :order="order" />
    </div>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  onMounted,
  nextTick,
  getCurrentInstance,
  onUnmounted,
  onBeforeUpdate
} from 'vue';
import PayType from '@/components/newPayType';
import { getOrdersList, cancelOrders, refundorders } from '@/service/order';
import { checkorderispay } from '@/service/home';
import { useRouter, useRoute } from 'vue-router';
import popup from '@/components/popup/index.vue';
import moment from 'moment';
import { util } from '../utils';
import { nftUtils, localStorageUtil } from '@/utils';

import OpenTheBox from '@/components/OpenTheBox.vue';
import PayCbNew from '@/components/PayCbNew.vue';
export default {
  name: 'Order',
  components: {
    popup,
    PayType,
    OpenTheBox,
    PayCbNew
  },
  setup() {
    const { proxy, ctx } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const from = route.query.from;

    if ('sand' === from && localStorage.getItem('sandpay_mobile')) {
      //如果来自衫德支付认证成功
      router.push({ path: '/', query: { from: 'sand' } });
    }

    const state = reactive({
      status: '',
      finished: false,
      loading: true,
      list: [],
      page: 1,
      totalPage: 0,
      show: false,
      orderStatus: {
        1: '支付成功',
        2: '已取消',
        3: '已超时'
      },
      orderId: null,
      orderIndex: null,
      server_time: moment(),
      isApp: nftUtils.isInApp(),
      backshow: false, // 退款提示弹窗
      orderItem: {},
      knownBack: false,
      showPayTyoe: false,
      boxitem: null,
      payStatus: 1, //1--正常支付，2--预付款
      showOpenBox: false,
      order: '',
      tabStatus: 0,
      showNewBox: false,
      isLoading: false,
      isLoadError: false
    });

    onBeforeUpdate(async () => {
      if (localStorageUtil.get('orderId')) {
        // 判断是否有已经支付成功的订单
        let trade_no = localStorageUtil.get('orderId');
        // 是新人盲盒
        let isNew = localStorageUtil.get('isNew') ? localStorageUtil.get('isNew') : 0;
        localStorageUtil.del('isNew');
        const { data } = await checkorderispay({ trade_no });
        localStorageUtil.del('orderId');

        if (data) {
          state.order = trade_no;
          setTimeout(() => {
            if (isNew == 1) {
              // 弹窗--新人盲盒
              // state.showNewBox = true;
            } else {
              state.showOpenBox = true;
            }
          }, 20);
          // router.push({ path: `/boxopen/${trade_no}` });
        }
      }
    });

    const goToDetail = item => {
      nftUtils.setcnzzRecordByBtn('前往支付订单');
      state.boxitem = item;

      item.sell_type == 1 && localStorageUtil.set('orderId', item.trade_no);
      state.payStatus = item.sell_type;
      state.showPayTyoe = true;
      // 判断支付方式
      // window.location.href = JSON.parse(item.pay_content).url;
      // util.alipay(id,router);
    };

    // 唤起退款弹窗
    const backPoup = (item, index) => {
      state.orderItem = item;
      state.orderIndex = index;
      state.backshow = true;
    };

    // 确定退款
    const sureBack = async () => {
      state.backshow = false;
      const { data } = await refundorders({ order_no: state.orderItem.trade_no });
      data && (state.knownBack = true);
    };

    const onChangeTab = name => {
      // 这里 Tab 最好采用点击事件，@click，如果用 @change 事件，会默认进来执行一次。
      state.tabStatus = name;
      state.page = 1;
      state.list = [];
      state.finished = false;
      state.loading = true;
      // getList();
      onLoad();
    };

    const knownIt = () => {
      state.knownBack = false;
      state.list[state.orderIndex].refund_status = 2;
    };

    const goBack = () => {
      router.go(-1);
    };

    const changePay = type => {
      switch (type) {
        case 11:
          return '支付宝';
        case 24:
          return '通联支付';
        case 25:
          return '杉德支付';
      }
    };

    // 请求列表
    const getList = () => {
      if (state.page === 1) {
        state.isLoading = true;
        state.isLoadError = false;
      }
      getOrdersList({
        page: state.page,
        page_size: 10,
        status: state.tabStatus
      })
        .then(res => {
          state.list = state.list.concat(res.data || []);
          state.loading = false;
          state.server_time = res.server_time;
          if (!res.data || !res.data.length) {
            state.finished = true;
          }
          if (res.data && res.data.length < 10) {
            state.finished = true;
          }
          if (state.page === 1) {
            state.isLoading = false;
            state.isLoadError = false;
          }
          // if (state.page >= data.totalPage) state.finished = true;
        })
        .catch(err => {
          state.loading = false;
          state.finished = true;
          if (state.page === 1) {
            state.isLoading = false;
            state.isLoadError = true;
          }
        });
    };

    // 加载列表
    const onLoad = () => {
      // 当加载还没结束时页码加一
      if (!state.finished && state.list.length) {
        state.page = state.page + 1;
      }
      getList();
    };

    // 打开取消订单弹窗
    const showPopup = (id, index) => {
      state.orderId = id;
      state.orderIndex = index;
      state.show = true;
    };

    // 获取格式时间
    const getTime = (time, format = 'YYYY-MM-DD HH:mm:ss') => {
      return moment(time).format(format);
    };

    // 计算与当前时间时间差
    const diffTime = time => {
      return moment(moment(time)).diff(moment(state.server_time), 'millisecond');
    };

    // 倒计时结束触发
    const finistTime = () => {
      // state.finished = false
      // state.loading = true
      // state.list = []
      // state.page = 1
      // onLoad()
      // // getList()
      // console.log('倒计时结束')
    };

    const changeTime = (val, item, index) => {
      if (val.total == 0) {
        state.list[index].status = 3;
      }
    };

    // 取消订单
    const cancelOrder = () => {
      nftUtils.setcnzzRecordByBtn('取消订单');
      cancelOrders({
        order_id: state.orderId
      }).then(res => {
        console.log(res);
        if (res.status == 0) {
          state.list[state.orderIndex].status = 2;
          state.show = false;
        }
      });
    };

    onMounted(() => {
      console.log(localStorageUtil.get('shareId'), from);
      state.loading = true;
      state.finished = false;
      state.list = [];
      state.page = 1;
      onLoad();
      if (localStorageUtil.get('shareId') && 'sand' === from) {
        router.push({
          path: '/wishSharePage',
          query: { shareId: localStorageUtil.get('shareId') }
        });
      }

      window.addEventListener('pageshow', function (e) {
        if (e.persisted || (window.performance && window.performance.navigation.type == 2)) {
          // alert(4449090898)
          // window.location.reload()
          window.location.reload();
        }
      });

      document.addEventListener('visibilitychange', e => {
        if (e.target.visibilityState === 'visible') {
          // 要执行的方法
          state.loading = true;
          state.finished = false;
          state.list = [];
          state.page = 1;
          onLoad();
        }
      });
      // document.addEventListener('visibilitychange',test)

      // watch(
      //   () => document.hidden,
      //   (newVal) => {
      //     alert('8798798',newVal)
      //   },
      //   { immediate: true,deep:true }
      // )
    });

    onUnmounted(() => {
      window.removeEventListener('pageshow', () => {});
    });

    return {
      ...toRefs(state),
      onChangeTab,
      goBack,
      onLoad,
      showPopup,
      getList,
      getTime,
      diffTime,
      finistTime,
      cancelOrder,
      goToDetail,
      changeTime,
      sureBack,
      backPoup,
      changePay,
      knownIt
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
* {
  touch-action: pan-y;
}
.heji {
  text-align: end;
  span {
    font-weight: bold;
    font-size: 16px;
  }
}
.item-bt {
  display: flex;
  justify-content: space-between;
  padding: 23px 14px 10px;
  border-top: 1px solid #494359;
  margin-top: 20px;
  font-size: 14px;
}
.point {
  display: flex;
  justify-content: space-between;
  padding: 0 14px;
  margin-top: 7px;
  font-size: 12px;
  span {
    color: rgba(255, 255, 255, 0.7);
  }
}
.wrapper {
  padding-bottom: 100px;
}
.prepaid-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 12px;
}
.pay-scucess {
  color: #3cdbe8 !important;
  font-size: 14px;
}
.has-back {
  font-size: 14px;
}
.content-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.prepaid {
  background-image: url('../imgs/comm/Slice35.png');
  background-size: 100% 100%;
  color: $fc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  width: 46px;
  height: 18px;
  margin-top: 4px;
}

.appTop {
  padding-top: 70px;
}
.order-box {
  .van-card__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .order-item-box {
    margin: 12px 16px;
    background-color: $primaryc;
    border-radius: 8px 8px 8px 8px;

    .order-item-header {
      padding: 0 14px;
      line-height: 36px;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
      border-bottom: 1px solid #494359;
    }

    .item-info {
      display: flex;
      justify-content: space-between;
      position: relative;
      padding: 20px 14px 0 14px;

      .item-img-name {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        p {
          margin: 0;
        }

        .itemImg {
          font-size: 0;
        }

        .name {
          margin-left: 12px;

          .title {
            font-size: 14px;
            font-weight: 500;
            color: #fff;
            overflow: hidden;

            text-overflow: ellipsis;

            display: -webkit-box;

            -webkit-box-orient: vertical;

            -webkit-line-clamp: 2;
          }

          .desc {
            width: 180px;
            font-size: 12px;
            margin-top: 7px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.7);
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }
        }
      }

      .item-price {
        font-size: 16px;
        font-weight: 500;
        color: #fff;
      }

      img {
        width: 70px;
        height: 70px;
        border-radius: 4px 4px 4px 4px;
      }
    }

    .item-time {
      font-size: 10px;
      padding: 7px 0px;
      text-align: right;

      .item-button {
        margin-bottom: 8px;

        button {
          border: none;
          width: 80px;
          height: 28px;
          border-radius: 4px 4px 4px 4px;
          background: transparent;
          font-size: 12px;
          font-weight: 500;
          color: #fff;
        }
        .cancel {
          border: 1px solid rgba(255, 255, 255, 0.5);
        }

        .pay-btn {
          margin-left: 10px;
          background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
          color: #1c172a;
        }
      }

      .countDown {
        display: inline-block;
        color: #e63f3f;
      }
    }
    .max-box {
      height: 38px !important
   ;
    }
    .back {
      // position: absolute;
      margin: 0;
      // right: 14px;
      // top: 10px;
      // transform: translateY(-50%);
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      // padding: 5px 17px;
      display: flex;
      width: 80px;
      height: 28px;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      margin-top: 8px;
      float: right;
    }
    .status-box {
      height: 18px;
      position: relative;
      .back {
        position: absolute;
        margin: 0;
        right: 14px;
        top: 10px;
        transform: translateY(-50%);
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        border-radius: 4px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        padding: 5px 17px;
      }

      p {
        position: absolute;
        margin: 0;
        right: 14px;
        top: 0;
        transform: translateY(-50%);
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }

  .dataNullBox {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    img {
      width: 150px;
      height: 110px;
    }

    .text {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
.popup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: left;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;

  .content {
    padding: 16px;
    line-height: 26px;
    .title {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .btn {
    div {
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      border-top: 1px solid #494359;
    }
  }
}
</style>
<style lang="scss">
#invite-award {
  .vantPop {
    background: transparent !important;
  }
}
</style>
